@media screen {

  /* ************************************************************************************** */
  /* ************************************************************************************** */
  /* JS  */
  /* ************************************************************************************** */
  /* ************************************************************************************** */

  body:not(.no-js-onload) {

    &.l-site-404 {
      background-image: $gradient-5, $bg-space;
    }

  }

}

@media print {

  #joinme {
    display: none;
  }

}
