@media screen {

  .l-site-footer {
    padding-bottom: 4rem;
    background-image: $gradient-6;
    @media (max-width: $bp-mb) {
      padding-right: 1rem;
      padding-left: 1rem;
    }
    @media (min-width: $bp-tb) {
      padding-right: 2rem;
      padding-left: 2rem;
    }
  }

  .l-site-footer-nav {
    max-width: 20rem;
    margin-top: 2rem;
    margin-right: auto;
    margin-left: auto;
    padding-top: 1rem;

    &::before {
      display: block;
      content: " ";
      width: 3rem;
      height: 1rem;
      margin: auto;
      border-top: .1rem solid $global-text-color;
    }

  }

  .l-site-footer-nav-title {
    padding: 0 1rem;
    font-size: 2em;
    letter-spacing: -.05em;
    color: $global-shade-color-3;
    text-align: center;
  }

  .l-site-baseline {
    margin-top: 2rem;
    @media (min-width: $bp-dt) {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
    }
  }

  .l-site-legal {
    margin: 0;
    font-size: .8em;
    color: rgba(255,255,255,.2);
    text-align: center;

    > a {
      color: rgba(255,255,255,.2);
    }

  }

  .l-site-baseline-nav {
    @include no-gutter;
    text-align: center;
  }

  .l-site-baseline-nav-item {
    font-size: .8em;
    color: rgba(255,255,255,.2);
    @media (max-width: $bp-dt - 1) {
      display: block;
      padding: .2rem 0;
    }
    @media (min-width: $bp-dt) {
      display: inline-block;
    }

    &::before {
      @media (min-width: $bp-dt) {
        content: "-";
        margin-left: .3rem;
      }
    }

  }

  .l-site-baseline-nav-link {
    @media (max-width: $bp-dt - 1) {
      display: block;
    }
    color: rgba(255,255,255,.2);
  }

  .l-site-badges {
    display: flex;
    justify-content: center;
  }

  .l-site-gotop {
    text-align: center;
  }

  /* ************************************************************************************** */
  /* ************************************************************************************** */
  /* JS  */
  /* ************************************************************************************** */
  /* ************************************************************************************** */

  body:not(.no-js-onload) {

    .l-site-footer-nav-title {
      margin: 0;
    }

    .l-site-legal {
      color: rgba(255,255,255,.2);

      > a {
        color: rgba(255,255,255,.2);
      }

    }

    .l-site-baseline-nav-item {
      color: rgba(255,255,255,.2);
    }

    .l-site-baseline-nav-link {
      color: rgba(255,255,255,.2);
    }

    .l-site-gotop-link {
      @include btn;
      position: fixed; top: .5rem; right: .5rem;
      z-index: 4000;

      &:focus,
      &:hover {

        .l-site-gotop-label {
          @include btn-label-focus;
        }

      }

      &:focus {
        @include outlineMasker;
      }

      &:active {

        .l-site-gotop-label {
          @include btn-label-click;
        }

      }

    }

    .l-site-gotop-label {
      @include btn-label;
      @include btn-label-dir(left);
    }

    &.js-no-scroll {

        .l-site-gotop-link {
          right: calc(.5rem + var(--scrollBarWidth));
        }

    }

    &:not(.js-is-loading) {

      .l-site-gotop-link {
        transition: opacity .3s ease-in;
      }

    }

    &.js-is-top {

      .l-site-gotop-link {
        opacity: 0;
      }

      &:not(.js-is-loading) {

        .l-site-gotop-link {
          transition: opacity .3s ease-in;
        }

      }

    }

    &:not(.js-is-end) {

      .l-site-footer-nav {
        opacity: 0;
        transition: opacity .3s ease-in;
      }

    }

    &.js-is-end {

      .l-site-footer-nav {
        opacity: 1;
        transition: opacity .5s .3s ease-in;
      }

    }

  }

  /* ************************************************************************************** */
  /* ************************************************************************************** */
  /* NO JS  */
  /* ************************************************************************************** */
  /* ************************************************************************************** */

  body.no-js-onload {

    .l-site-legal {
      color: $global-text-color;

      > a {
        color: $global-text-color;
      }

    }

    .l-site-baseline-nav-item {
      color: $global-text-color;
    }

    .l-site-baseline-nav-link {
      color: $global-text-color;
    }

    .l-site-gotop-link {
      font-size: .7em;
      color: $global-text-color;

      &::before {
        display: none;
      }

    }

  }

}

@media print {

  .l-site-footer-nav,
  .l-site-baseline-nav,
  .l-site-gotop,
  .js-notify {
    display: none;
  }

  .l-site-legal {
    padding-top: 1cm;
    text-align: center;

    &::after {
      content: " - www.thomas-marget.fr";
    }

  }

}
