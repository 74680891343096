@media screen {

  .l-modal {
    max-width: 35rem;
    margin: auto;
    text-align: left;
    color: $global-text-color;

    &[class*="ico-"] {

      &::before {
        border-radius: 100%;
        background-color: $global-shade-color-2;
        @media (max-width: $bp-mb) {
          $ico-size: 3rem;
          width: $ico-size;
          height: $ico-size;
          top: 1rem; left: 1rem;
          font-size: 2rem;
          line-height: $ico-size;
        }
        @media (min-width: $bp-tb) {
          $ico-size: 5.3rem;
          width: $ico-size;
          height: $ico-size;
          font-size: 3rem;
          line-height: $ico-size;
        }
      }

    }

    &.ico-lbl {

      &::before {
        @media (max-width: $bp-mb) {
          font-size: .9rem;
          line-height: 3rem;
        }
        @media (min-width: $bp-tb) {
          font-size: 1.5rem;
        }
      }

    }

  }

  .l-modal-header {
    padding-right: 2rem;
  }

  .l-modal-title {
    margin: 0;
    padding: 0;
    color: $global-text-color;

    &[tabindex] {
      @include anchor-label(1.6rem, rgba(0,0,0,.8));
    }

  }

  .l-modal-body {
    font-size: .9em;

    > h4,
    > h5,
    > h6 {

      &[tabindex] {
        @include anchor-label(1.2rem, rgba(0,0,0,.9));
      }

    }

    > h4 {
      margin-bottom: 0;
      padding: .3rem .7rem;
      border-radius: 2rem;
      background-color: $global-text-color;
    }

    > p {
      line-height: 1.5;

      a {
        @include link-classic;
      }

    }

    > ul {
      padding-left: 0;

      > li {
        display: block;
        position: relative;
        padding: .5rem 1rem;
        border-left: .4rem solid $global-text-color;

        &::before {
          $bullet-size: .4rem;
          display: block;
          content: " ";
          width: $bullet-size;
          height: $bullet-size;
          position: absolute; left : -.2rem;
          border-radius: 100% 0 0 0;
          transform: rotate(-45deg);
          background-color: $global-text-color;
        }
      }

    }

  }

  /* ************************************************************************************** */
  /* ************************************************************************************** */
  /* JS  */
  /* ************************************************************************************** */
  /* ************************************************************************************** */

  body:not(.no-js-onload) {

    .js-modal-overlay {
      position: fixed;
      top: 0;
      z-index: 5500;
      @media (max-width: $bp-mb) {
        width: 100%;
        height: 100%;
        background-image: $gradient-5;
      }
      @media (min-width: $bp-tb) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(100% - 2rem);
        height: calc(100% - 2rem);
        padding: 1rem;
        background-color: $global-overlay-color-2;
      }

      &.js-offset {
        @media (max-width: $bp-mb) {
          right: -100%;
          transition: right .3s ease-in;
        }
        @media (min-width: $bp-tb) {
          right: -100%;
          opacity: 0;
          transition: opacity .3s ease-in .3s, right 0s .6s;
        }

        .js-modal {
          @media (min-width: $bp-tb) {
            opacity: 0;
            transform: matrix3d(0.01, 0, 0.00, 0, 0.00, 1, 0.00, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transition: transform .3s cubic-bezier(1,-0.03,.9,.61), opacity .1s ease-out .2s;
          }

        }

      }

      &:not(.js-offset) {
        @media (max-width: $bp-mb) {
          right: 0;
          transition: right .3s ease-out;
        }
        @media (min-width: $bp-tb) {
          right: 0;
          opacity: 1;
        }

        .js-modal {
          @media (min-width: $bp-tb) {
            opacity: 1;
            transform: matrix3d(1, 0, 0.00, 0, 0.00, 1, 0.00, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transition: transform .3s ease-in, opacity .6s;
          }

          &::before {
            @media (min-width: $bp-tb) {
              opacity: 1;
              transform: scale(1,1);
              transition: all .3s .3s;
            }
          }

        }

      }

    }

    .js-modal-scroll {
      overflow-y: auto;
    }

    .js-modal {
      position: relative;
      @media (max-width: $bp-mb) {
        padding: 1rem;
      }
      @media (min-width: $bp-tb) {
        padding: 2rem;
        border-radius: 4rem;
        background-color: $global-overlay-color;
        background-image: $gradient-1;
        box-shadow: $box-shadow-5;
      }

      &[class*="ico-"] {
        @media (max-width: $bp-mb) {
          padding-top: 5rem;
        }
        @media (min-width: $bp-tb) {
          padding-left: 8rem;
        }

        &::before {
          @media (min-width: $bp-tb) {
            top: 1rem; left: 1rem;
            opacity: 0;
            transform: scale(.1,.1);
          }
        }

      }

    }

    .js-modal-cta-btn {
      @extend .btn-cta;
    }

    .js-modal-cta-btn-label {
      @extend .btn-cta-label;
    }

    .js-modal-close-btn {
      @include btn;
      position: absolute;
      @media (max-width: $bp-mb) {
        top: .5rem; right: .5rem;
      }
      @media (min-width: $bp-tb) {
        top: -.5rem; right: -.5rem;
      }
    }

    .js-modal-close-btn-label {
      @include masker;
    }

    .l-modal-title {
      font-size: 1.6em;

      &[tabindex] {

        &:focus {
          @include outlineMasker;
        }

      }

    }

    .l-modal-body {
      padding-top: 1rem;
      padding-bottom: 1rem;

      > h4,
      > h5,
      > h6 {
        font-weight: 300;
        color: $global-shade-color-2;

        &[tabindex] {

          &:focus {
            @include outlineMasker;
          }

        }

      }

    }

    .l-modal-footer {
      display: none;
    }

  }

  /* ************************************************************************************** */
  /* ************************************************************************************** */
  /* NO JS  */
  /* ************************************************************************************** */
  /* ************************************************************************************** */

  body.no-js-onload {

    .js-modal-overlay {
      @media (min-width: $bp-tb) {
        margin-top: 3rem;
        margin-bottom: 3rem;
      }
    }

    .js-modal {
      border-radius: 2rem;

      &[class*="ico-"] {
        padding: 2rem 1rem 1rem 1rem;
        background-color: $global-overlay-color-3;
        @media (max-width: $bp-mb) {
          margin-bottom: 1rem;
          padding-top: 5rem;
          padding-left: 1rem;
        }
        @media (min-width: $bp-tb) {
          min-height: 6rem;
          padding-left: 9rem;
        }

        &::before {
          @media (min-width: $bp-tb) {
            top: 2rem; left: 2.5rem;
          }
        }

      }

    }

    .js-modal-cta-btn,
    .js-modal-close-btn {
      display: none;
    }

    .l-modal-title {
      font-size: 1.2em;
    }

    .l-modal-body {

      > h4,
      > h5,
      > h6 {
        color: $global-shade-color-1;
      }

      a {
        font-weight: 700;
      }

    }

    .l-modal-footer {
      font-size: .9em;

      a {
        @include link-classic;
        font-weight: 700;

        &[class*="ico-"] {
          padding-left: 1rem;

          &::before {
            left: 0;
          }

          &::after {
            width: calc(100% - 1rem);
          }

        }

      }

    }

  }

}

@media print {

  .l-modal-footer,
  .l-modal button {
    display: none;
  }

  .l-modal-overlay {
    margin-left: 4%;
  }

  .l-modal {
    border-top: 1mm solid $global-main-color;

    &:not(.ico-lbl) {

      &::before {
        font-size: 30pt;
      }

    }

    &.ico-lbl {

      &::before {
        font-size: 24pt;
      }

    }


  }

  .l-modal-title {
    margin-top: 2mm;
  }

}
