@media screen {

  /* ************************************************************************************** */
  /* ************************************************************************************** */
  /* JS  */
  /* ************************************************************************************** */
  /* ************************************************************************************** */

  .js-notify {

    &:not(.js-offset) {
      position: fixed; bottom: 1rem; left: 1rem;
      margin: 0;
      padding: .7rem;
      border-radius: .3rem;
      font-size: .8em;
      color: $global-text-color;
      background-color: rgba(0,0,0,.9);
    }

    &.js-offset {
      padding: 0 1rem;
      font-size: .7em;
      color: rgba(255,255,255,.2);
      text-align: center;
    }

  }

  body:not(.no-js-onload) {

    &.js-no-scroll {
      overflow: hidden;
      padding-right: var(--scrollBarWidth);

      &.js-is-top {

        &::after {
          opacity: 0;
          transition: opacity 1s ease-out;
        }

      }

    }

  }

}

@media print {

}
