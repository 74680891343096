@mixin icons-list {
  .ico-accessibility:before {
    content: "\e900";
  }
  .ico-anchor:before {
    content: "\e920";
  }
  .ico-arrow-down:before {
    content: "\e901";
  }
  .ico-arrow-left:before {
    content: "\e902";
  }
  .ico-arrow-right:before {
    content: "\e903";
  }
  .ico-arrow-up:before {
    content: "\e904";
  }
  .ico-automation:before {
    content: "\e905";
  }
  .ico-close:before {
    content: "\e906";
  }
  .ico-cms:before {
    content: "\e907";
  }
  .ico-company:before {
    content: "\e908";
  }
  .ico-creation:before {
    content: "\e909";
  }
  .ico-eco:before {
    content: "\e90a";
  }
  .ico-email:before {
    content: "\e90b";
  }
  .ico-en:before {
    content: "\e90c";
  }
  .ico-fb:before {
    content: "\e90d";
  }
  .ico-li:before {
    content: "\e90e";
  }
  .ico-be:before {
    content: "\e921";
  }
  .ico-gh:before {
    content: "\e922";
  }
  .ico-git:before {
    content: "\e923";
  }
  .ico-marketing:before {
    content: "\e90f";
  }
  .ico-menu:before {
    content: "\e910";
  }
  .ico-modularity:before {
    content: "\e911";
  }
  .ico-optimization:before {
    content: "\e912";
  }
  .ico-project:before {
    content: "\e913";
  }
  .ico-prototype:before {
    content: "\e914";
  }
  .ico-responsive:before {
    content: "\e915";
  }
  .ico-school:before {
    content: "\e916";
  }
  .ico-semantic:before {
    content: "\e917";
  }
  .ico-seo:before {
    content: "\e918";
  }
  .ico-skills:before {
    content: "\e919";
  }
  .ico-soft:before {
    content: "\e91a";
  }
  .ico-ui:before {
    content: "\e91b";
  }
  .ico-w3c:before {
    content: "\e91c";
  }
  .ico-watch:before {
    content: "\e91d";
  }
  .ico-wp:before {
    content: "\e91e";
  }
  .ico-transversal:before {
    content: "\e91f";
  }
  .ico-stopwatch:before {
    content: "\e924";
  }
  .ico-heart:before {
    content: "\e925";
  }
  .ico-comments:before {
    content: "\e926";
  }
  .ico-hammer:before {
    content: "\e927";
  }
  .ico-lightbulb:before {
    content: "\e928";
  }
  .ico-flask:before {
    content: "\e929";
  }
  .ico-smile:before {
    content: "\e92a";
  }
  .ico-sun:before {
    content: "\e92b";
  }
  .ico-recycle:before {
    content: "\e92c";
  }
}

@media screen {

  [class*="ico-"] {
    position: relative;
  }

  [class*="ico-"]::before {
    @include font-icons;
  }

  [class*="ico-"]:focus::before,
  [class*="ico-"]:hover::before {
    text-decoration: none;
  }

  .ico-lbl::before {
    content: attr(data-label);
    font-family: $font-1;
    font-weight: 700;
    letter-spacing: -.1em;
  }

  @include icons-list;

}

@media print {

  [class*="ico-"]::before {
    margin: auto;
    font-family: $font-icons;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    color: $global-main-color !important;
    text-align: center;
    text-transform: none;
    white-space: pre-wrap;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .ico-lbl::before {
    content: attr(data-label);
    font-family: $font-1;
    font-weight: 700;
    letter-spacing: -.1em;
  }

  @include icons-list;

}
