@font-face {
  font-family: "Poppins";
  src: url(../fonts/poppins-300/poppins-300.eot);
  src: url(../fonts/poppins-300/poppins-300.eot) format('embedded-opentype'),
  url(../fonts/poppins-300/poppins-300.woff2) format('woff2'),
  url(../fonts/poppins-300/poppins-300.woff) format('woff'),
  url(../fonts/poppins-300/poppins-300.ttf) format('truetype'),
  url(../fonts/poppins-300/poppins-300.svg) format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Poppins";
  src: url(../fonts/poppins-400/poppins-400.eot);
  src: url(../fonts/poppins-400/poppins-400.eot) format('embedded-opentype'),
  url(../fonts/poppins-400/poppins-400.woff2) format('woff2'),
  url(../fonts/poppins-400/poppins-400.woff) format('woff'),
  url(../fonts/poppins-400/poppins-400.ttf) format('truetype'),
  url(../fonts/poppins-400/poppins-400.svg) format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Poppins";
  src: url(../fonts/poppins-700/poppins-700.eot);
  src: url(../fonts/poppins-700/poppins-700.eot) format('embedded-opentype'),
  url(../fonts/poppins-700/poppins-700.woff2) format('woff2'),
  url(../fonts/poppins-700/poppins-700.woff) format('woff'),
  url(../fonts/poppins-700/poppins-700.ttf) format('truetype'),
  url(../fonts/poppins-700/poppins-700.svg) format('svg');
  font-weight: 700;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "tmicons";
  src: url(../fonts/tmicons/tmicons.eot);
  src: url(../fonts/tmicons/tmicons.eot) format('embedded-opentype'),
  url(../fonts/tmicons/tmicons.woff) format('woff'),
  url(../fonts/tmicons/tmicons.ttf) format('truetype'),
  url(../fonts/tmicons/tmicons.svg) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
