@media screen {

  .l-timeline {
    display: flex;
    position: relative;
    padding: 2rem 0;
    border-left-style: solid;
    border-left-color: rgba(255,255,255,.2);
    @media (max-width: $bp-mb) {
      margin: 3rem 0 3rem 2rem;
      border-left-width: .5rem;
    }
    @media (min-width: $bp-tb) {
      margin: 5rem 0 5rem 4rem;
      border-left-width: 1rem;
    }

    &::before,
    &::after {
      display: block;
      content: " ";
      position: absolute;
      border-radius: 1rem;
      background-color: rgba(255,255,255,.2);
      @media (max-width: $bp-mb) {
        $bullet-size: 1.5rem;
        width: $bullet-size;
        height: $bullet-size;
      }
      @media (min-width: $bp-tb) {
        $bullet-size: 3rem;
        width: $bullet-size;
        height: $bullet-size;
      }
    }

    &::before {
      top: 100%;
      @media (max-width: $bp-mb) {
        left: -1rem;
      }
      @media (min-width: $bp-tb) {
        left: -2rem;
      }
    }

    &::after {
      bottom: 100%;
      @media (max-width: $bp-mb) {
        left: -1rem;
      }
      @media (min-width: $bp-tb) {
        left: -2rem;
      }
    }

  }

  .l-timeline-item {
    display: block;
    @media (max-width: $bp-mb) {
      margin-left: -2rem;
    }
    @media (min-width: $bp-tb) {
      margin-left: -3.5rem;
    }

  }

  .l-timeline-title {
    display: grid;
    color: $global-text-color;
    text-decoration: none;
    @media (max-width: $bp-mb) {
      grid-template-columns: 3.5rem 1fr;
    }
    @media (min-width: $bp-tb) {
      grid-template-columns: 6rem 1fr;
    }

    &:focus {

      .l-timeline-title-label {
        color: $global-shade-color-2;
        background-color: $global-shade-color-3;
      }

    }

    &:hover {

      .l-timeline-title-label {

        &::before {
          transform: translateX(0);
          transition: transform .3s ease-in;
        }

      }

    }

    &:active {

      .l-timeline-date {

        &::after {
          transform: scale(2);
          z-index: 10000;
          opacity: .3;
          transition: transform .005s ease-in;
        }

      }

    }

  }

  .l-timeline-date {
    display: block;
    color: rgba(255,255,255,0);
    text-align: center;
    transition: transform .1s ease-out;

    &::before {
      width: 100%;
      top: .5rem; left: 0;
      padding: .5rem 0;
      border-radius: 2rem;
      background-color: $global-text-color;
      box-shadow: $box-shadow-5;
      @media (max-width: $bp-mb) {
        font-size: .8rem;;
      }
      @media (min-width: $bp-tb) {
        font-size: 1.5rem;
      }
    }

  }

  .l-timeline-title-label {
    display: block;
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin-left: .5rem;
    padding: .7rem .7rem .7rem 3.5rem;
    border-radius: 2rem;
    background-color: $global-overlay-color-3;

    &::before {
      display: block;
      content: " ";
      width: 100%;
      height: 100%;
      position: absolute; top: 0; left: 0;
      z-index: -1;
      border-radius: 2rem;
      background-color: $global-shade-color-3;
      transform: translateX(-100%);
      transition: transform .3s ease-in;
    }

  }

  .l-timeline-title-info {
    display: block;
    position: static;
    padding-top: .5rem;
    font-size: .8em;

    &::before {
      top: 0; bottom: 0; left: .3rem;
      border-radius: 100%;
      @media (max-width: $bp-mb) {
        $ico-size: 2rem;
        width: $ico-size;
        height: $ico-size;
        font-size: 1.5rem;
        line-height: $ico-size;
      }
      @media (min-width: $bp-tb) {
        $ico-size: 3rem;
        width: $ico-size;
        height: $ico-size;
        font-size: 2rem;
        line-height: $ico-size;
      }
    }

  }

  /* ************************************************************************************** */
  /* ************************************************************************************** */
  /* JS  */
  /* ************************************************************************************** */
  /* ************************************************************************************** */

  body:not(.no-js-onload) {

    .l-timeline {

      &:not(.l-timeline-reverse) {
        flex-flow: column nowrap;

        .l-timeline-item {

          &:not(:last-child) {
            margin-bottom: 3rem;
          }

        }

      }

      &.l-timeline-reverse {
        flex-flow: column-reverse nowrap;

        .l-timeline-item {

          &:not(:first-child) {
            margin-bottom: 2rem;
          }

        }

      }

    }

    .l-timeline-title {

      &:focus {
        @include outlineMasker;

        .l-timeline-title-label {
          color: $global-shade-color-2;
        }

        .l-timeline-title-info {

          &::before {
            color: $global-shade-color-2;
          }

        }

      }

      &:hover {

        .l-timeline-title-label {
          color: $global-shade-color-2;
        }

        .l-timeline-title-info {

          &::before {
            color: $global-shade-color-2;
          }

        }

      }

    }

    .l-timeline-date {

      &::before {
        font-weight: 300;
        color: $global-shade-color-2;
      }

    }

    .js-timeline {

      &:not(.js-trigger) {
        opacity: 0;
      }

      &.js-trigger {
        opacity: 1;
        transition: opacity .3s ease-out .5s;
      }

    }

  }

  /* ************************************************************************************** */
  /* ************************************************************************************** */
  /* NO JS  */
  /* ************************************************************************************** */
  /* ************************************************************************************** */

  body.no-js-onload {

    .l-timeline {
      flex-flow: column nowrap;
    }

    .l-timeline-item {

      &:not(:last-child) {
        margin-bottom: 2rem;
      }

    }

    .l-timeline-title {

      &:focus {

        .l-timeline-title-label {
          color: $global-shade-color-1;
        }

        .l-timeline-title-info {

          &::before {
            color: $global-shade-color-1;
          }

        }

      }

      &:hover {

        .l-timeline-title-label {
          color: $global-shade-color-1;
        }

        .l-timeline-title-info {

          &::before {
            color: $global-shade-color-1;
          }

        }

      }

    }

    .l-timeline-date {

      &::before {
        color: $global-shade-color-1;
      }

    }

  }

}
