@media screen {

  .l-site-socials {
    display: grid;
    grid-template-columns: repeat(4, 3rem);
    grid-column-gap: 1rem;
    justify-content: center;
    max-width: 20rem;
    margin-right: auto;
    margin-left: auto;
    padding: 0;
  }

  .l-site-socials-item {
    display: inline-block;
  }

  .l-site-socials-link {
    $ico-size: 3rem;
    display: block;
    width: $ico-size;
    height: $ico-size;

    &::before {
      $ico-size: 3rem;
      width: $ico-size;
      height: $ico-size;
      top: 0; right: 0; bottom: 0; left: 0;
      border-radius: 100%;
      font-size: $ico-size - 1;
      line-height: $ico-size;
      color: $global-shade-color-2;
      background-color: $global-text-color;
      transition: background-color .1s ease-in;
    }

    &:focus,
    &:hover {

      &::before {
        color: $global-text-color;
        background-color: $global-shade-color-2;
        transition: background-color .1s ease-in;
      }

    }

  }

  .l-site-socials-link-label {
    @include masker;
  }

  /* ************************************************************************************** */
  /* ************************************************************************************** */
  /* JS  */
  /* ************************************************************************************** */
  /* ************************************************************************************** */

  body:not(.no-js-onload) {

    .l-site-socials-link {

      &:focus {
        @include outlineMasker;
      }

    }

  }

}

@media print {

  .l-site-socials {
    display: none;
  }

}
