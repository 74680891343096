@media screen {

  .l-page-cms {
    padding-top: 1rem;
    @media (min-width: $bp-dt) {
      padding-top: 4rem;
    }

    > h1 {
      font-size: 3em;
      letter-spacing: -.05em;
      text-shadow: $box-shadow-5;
    }

    > h2,
    > h3,
    > h4,
    > h5,
    > h6 {

      &[tabindex] {
        @include anchor-label(1.6rem, rgba(0,0,0,.9));
      }

    }

    > h2 {
      padding: .5rem 1rem;
      border-radius: 2rem;
      letter-spacing: -.05em;
      background-color: $global-shade-color-3;
      box-shadow: $box-shadow-5;
      @media (max-width: $bp-mb) {
        margin-top: 3rem;
      }
      @media (min-width: $bp-tb) {
        margin-top: 5rem;
        font-size: 2em;
      }
    }

    > h3 {
      display: inline-block;
      padding: .5rem 1rem;
      border-radius: 2rem;
      font-size: 1.1em;
      background-color: $global-text-color;
      box-shadow: $box-shadow-5;
      @media (max-width: $bp-mb) {
        margin-bottom: 0;
      }
    }

    > p, ul {
      font-size: .9em;
      line-height: 1.5;
    }

    a {
      @include link-classic;
    }

    > table {
      border-collapse: collapse;

      caption {
        padding: .3rem;
        border-radius: .2rem .2rem 0 0;
        font-size: .7em;
        color: $global-shade-color-1;
        text-align: left;
        background-color: $global-text-color;
      }

      th,
      td {
        padding: .5rem;
        border: .05rem dotted $global-text-color;
        font-size: .8em;

      }

      th {
        border-top: 0;
      }

    }

    q {
      font-style: italic;
    }

    kbd {
      display: inline-block;
      padding: .2rem .5rem;
      border: .1rem solid $global-text-color;
      border-radius: .2rem;
      font-size: .8em;
      color: $global-text-color;
      background-color: $global-shade-color-1;
    }

  }

  /* ************************************************************************************** */
  /* ************************************************************************************** */
  /* JS  */
  /* ************************************************************************************** */
  /* ************************************************************************************** */

  body:not(.no-js-onload) {

    .l-page-cms {

      > h2,
      > h3,
      > h4,
      > h5,
      > h6 {
        font-weight: 300;
        color: $global-shade-color-2;

        &[tabindex] {

          &:focus {
            @include outlineMasker;
          }

        }

      }

    }

  }

  /* ************************************************************************************** */
  /* ************************************************************************************** */
  /* NO JS  */
  /* ************************************************************************************** */
  /* ************************************************************************************** */

  body.no-js-onload {

    .l-page-cms {

      > h2,
      > h3,
      > h4,
      > h5,
      > h6 {
        color: $global-shade-color-1;
      }

      a {
        font-weight: 700;
      }

    }

  }

}

@media print {

  .l-page-cms {
    margin-left: 4%;

    > h1 {
      font-size: 40pt;
      line-height: 1;

      &::after {
        display: block;
        content: "www.thomas-marget.fr";
        font-size: 20pt;
        letter-spacing: -.7mm;
        color: rgba(0,0,0,.5);
      }

    }

    > table {

      caption {
        font-style: italic;
        text-align: left;
      }

      th,
      td {
        padding: 1mm;
        border: .5mm solid $global-main-color;
      }

    }

  }

}
