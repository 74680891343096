@media screen {

  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes beating {
    0% {
      transform: scale(1);
    }
    2% {
      transform: scale(1.5);
    }
    4% {
      transform: scale(1);
    }
    6% {
      transform: scale(1.5);
    }
    10% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes bounceY {
    0% {
      transform: translateY(0);
    }
    25% {
      transform: translateY(.3rem);
    }
    75% {
      transform: translateY(-.3rem);
    }
    100% {
      transform: translateY(0);
    }
  }

  /* ************************************************************************************** */
  /* ************************************************************************************** */
  /* JS  */
  /* ************************************************************************************** */
  /* ************************************************************************************** */

  body:not(.no-js-onload) {

    /* --- Animations Names ------------------------------- */

    /* --- Fade --- */

    [data-name*="fade"] { transition-property: opacity; }

    [data-name="fadein"],
    .js-animate--trigger + [data-name="fadein"] { opacity: 0; }

    [data-name="fadein"].js-animate--active,
    .js-animate--trigger.js-animate--active  + [data-name="fadein"] {  opacity: 1; }

    /* --- Move --- */

    [data-name*="move"] { transition-property: transform; }

    [data-name="movetop"],
    .js-animate--trigger + [data-name="movetop"] { transform: translateY(100vh); }
    [data-name="movetop"].js-animate--active,
    .js-animate--trigger.js-animate--active  + [data-name="movetop"] { transform: translateY(0); }

    [data-name="moveright"],
    .js-animate--trigger + [data-name="moveright"] { transform: translateX(-100vw); }
    [data-name="moveright"].js-animate--active,
    .js-animate--trigger.js-animate--active  + [data-name="moveright"] { transform: translateX(0); }

    [data-name="moveleft"],
    .js-animate--trigger + [data-name="moveleft"] { transform: translateX(100vw); }
    [data-name="moveleft"].js-animate--active,
    .js-animate--trigger.js-animate--active  + [data-name="moveleft"] { transform: translateX(0); }

    /* --- Animations Duration ------------------------------- */

    [data-duration="1"] { transition-duration: .1s }
    [data-duration="2"] { transition-duration: .2s }
    [data-duration="3"] { transition-duration: .3s }
    [data-duration="4"] { transition-duration: .4s }
    [data-duration="5"] { transition-duration: .5s }
    [data-duration="6"] { transition-duration: .6s }
    [data-duration="7"] { transition-duration: .7s }
    [data-duration="8"] { transition-duration: .8s }
    [data-duration="9"] { transition-duration: .9s }
    [data-duration="10"] { transition-duration: 1s }

    /* --- Animations Delays ------------------------------- */

    [data-delay="1"] { transition-delay: .1s }
    [data-delay="2"] { transition-delay: .2s }
    [data-delay="3"] { transition-delay: .3s }
    [data-delay="4"] { transition-delay: .4s }
    [data-delay="5"] { transition-delay: .5s }
    [data-delay="6"] { transition-delay: .6s }
    [data-delay="7"] { transition-delay: .7s }
    [data-delay="8"] { transition-delay: .8s }
    [data-delay="9"] { transition-delay: .9s }
    [data-delay="10"] { transition-delay: 1s }

    /* --- Animations Timing ------------------------------- */

    [data-timing="easein"] { transition-timing-function: ease-in; }
    [data-timing="easeout"] { transition-timing-function: ease-out; }
    [data-timing="easeinout"] { transition-timing-function: ease-in-out; }
    [data-timing="elastic"] { transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); }

  }

}
