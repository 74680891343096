@media screen {

    .l-site-banner {
      max-width: $max-layout-width;
      overflow: hidden;
      margin: 2rem auto;
      border-radius: 2rem;
      background-image: $gradient-1;
      box-shadow: $box-shadow-2;
      @media (max-width: $bp-mb) {
        padding: 1rem;
      }
      @media (min-width: $bp-tb) {
        padding: 2rem;
      }
    }

    .l-site-banner-btn {
      display: inline-block;
      position: relative;
      padding: .5rem 1.5rem;
      border: 0;
      border-radius: 1rem;
      font-family: $font-1;
      font-size: 1.2em;
      font-weight: 700;
      color: $global-text-color;
      letter-spacing: -.05em;
      text-decoration: none;
      background-color: $global-shade-color-2;
      box-shadow: $box-shadow-2;
    }

    .l-site-banner-title {
      display: inline-block;
      padding: .5rem 1.5rem;
      border-radius: 1rem;
      color: $global-text-color;
      letter-spacing: -.05em;
      background-color: $global-shade-color-2;
      @media (min-width: $bp-tb) {
        font-size: 2.5em;
      }
      @media (min-width: $bp-dt) {
        margin-left: 15rem;
      }

      &[tabindex] {
        @include anchor-label(2rem, rgba(0,0,0,.9));
      }

    }

    .l-site-banner-body {
      max-width: 45rem;
      @media (max-width: $bp-mb) {
        padding: 0 2rem 0 0;
      }
      @media (min-width: $bp-tb) {
        padding: 0 2rem 2rem 0;
      }
      @media (min-width: $bp-dt) {
        margin-left: 15rem;
      }

      &::before {
        opacity: .2;
        @media (max-width: $bp-mb) {
          display: none;
        }
        @media (min-width: $bp-tb) {
          $ico-size: 10rem;
          width: $ico-size;
          height: $ico-size;
          bottom: 4rem; left: -25rem;
          font-size: $ico-size;
        }
        @media (min-width: $bp-dt) {
          $ico-size: 25rem;
          width: $ico-size;
          height: $ico-size;
          bottom: 4rem; left: -25rem;
          font-size: $ico-size;
        }
      }

      > p {
        line-height: 1.5;

        > strong {

          &::before {
            content: "#";
            color: $global-shade-color-2;
          }

        }

      }

      .btn-cta {
        display: inline-block;
        margin-top: 1rem;
        background-color: rgba(0,0,0,0);
      }

    }

    /* ************************************************************************************** */
    /* ************************************************************************************** */
    /* JS  */
    /* ************************************************************************************** */
    /* ************************************************************************************** */

    body:not(.no-js-onload) {

      .l-site-banner-btn {
        position: absolute; top: .5rem; right: .5rem;
        z-index: 4500;
        font-size: .9em;
        text-align: center;
        transform-origin: right top;

        &::after {
          display: block;
          content: " ";
          width: 2rem;
          height: 2rem;
          position: absolute; top: 0; right: 0; left: 0;
          z-index: -1;
          opacity: 0;
          margin: auto;
          border-radius: 100%;
          background-color: $global-text-color;
          transition: all .3s ease-in;
        }

        &:focus {
          @include outlineMasker;
        }

        &:active {

          &::after {
            z-index: 10000;
            opacity: .1;
            transform: scale(3);
            transition: transform .005s ease-in;
          }

        }

      }

      .l-site-banner-title {

        &:focus {
          @include outlineMasker;
        }

      }

      &.js-intro-complete {

        .l-site-banner-btn {
          animation: beating ease-in 5s .3s;
        }

      }

    }

    /* ************************************************************************************** */
    /* ************************************************************************************** */
    /* NO JS  */
    /* ************************************************************************************** */
    /* ************************************************************************************** */

    body.no-js-onload {

      .l-site-banner-trigger {
        text-align: center;
      }

    }

}

@media print {



}
