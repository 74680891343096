@media screen {

  .l-site-nav-menu {
    display: block;
    position: relative;
    margin: 0;
  }

  .l-site-nav-link {
    display: inline-block;
    color: $global-text-color;
    text-decoration: none;
  }

  /* ************************************************************************************** */
  /* ************************************************************************************** */
  /* JS  */
  /* ************************************************************************************** */
  /* ************************************************************************************** */

  body:not(.no-js-onload) {

    .l-site-nav {

      &.js-offset {

        .l-site-nav-menu {
          opacity: 0;
          transform: translateY(-100%);
          transition: all .3s ease-in;

          &::before {
            opacity: 0;
            transform: scale(.5);
            transition: all .1s .5s ease-in;
          }

        }

        .l-site-socials {

          &::before {
            opacity: 0;
            transform: scale(.1);
            transition: opacity .3s ease-in, transform .3s ease-in;
          }

        }

      }

      &:not(.js-offset) {

        .l-site-nav-panel {

          &::before {
            opacity: .1;
            transition: opacity .5s ease-out .3s;
          }

        }

        .l-site-nav-menu {
          opacity: 1;
          transform: translateY(0);
          transition: all .3s .3s ease-out;

          &::before {
            opacity: 1;
            transform: scale(1);
            transition: all .1s .6s ease-out;
          }

        }

        .l-site-socials {

          &::before {
            opacity: 1;
            transform: scale(1);
            transition: opacity .3s .7s ease-out, transform .3s .7s ease-out;
          }

        }

      }

      .l-site-socials {
        position: relative;
      }

    }

    .l-site-nav-panel {
      height: 100%;
      overflow-x: hidden;

      &::before {
        @media (min-width: $bp-tb) {
          display: block;
          content: " ";
          width: 100%;
          height: 100%;
          position: absolute; top: 0; left: 0;
          opacity: 0;
          background-repeat: no-repeat;
          background-position: right center;
          background-size: cover;
          background-image: $bg-sea;
          transition: opacity .3s ease-in;
        }
      }

    }

    .l-site-nav-menu {
      z-index: 50;
      padding: 13rem 0 1rem 0;

      &::before {
        $ico-size: 7rem;
        display: block;
        content: " ";
        width: $ico-size;
        height: $ico-size;
        position: absolute; top: 4rem; right: 0; left: 0;
        margin: auto;
        border-radius: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100%;
        background-image: $bg-logo;
        box-shadow: $box-shadow-1;
      }

    }

    .l-site-nav-item {
      display: block;
      position: relative;
      padding: .5rem 1rem;
      text-align: center;

      &.js-active {

        .l-site-nav-link {
          color: $global-shade-color-2;
        }

      }

    }

    .l-site-nav-link {
      width: calc(100% + .07em);
      position: relative;
      z-index: 50;
      font-weight: 700;
      letter-spacing: -.05em;
      @include outlineMasker;
      @media (max-width: $bp-mb) {
        font-size: 2em;
      }
      @media (min-width: $bp-tb) {
        font-size: 3em;
      }

      &:focus,
      &:hover {

        .l-site-nav-link-label {
          color: $global-shade-color-3;
        }

      }

      &:focus {
        @include outlineMasker;
      }

    }

    .l-site-nav-link-label {
      display: block;
      transition: transform .05s linear;
    }

    .l-site-nav-info {
      display: inline-grid;
      grid-template-columns: 2rem auto;
      grid-column-gap: 1rem;
      justify-content: center;
      align-items: center;
      position: fixed; right: 0; bottom: 1rem; left: 0;
      margin: auto;
      padding: 1rem;
      animation: bounceY 1.5s ease-in-out .1s infinite;
    }

    .l-site-nav-info-icon {
      fill: $global-text-color;
    }

    .l-site-nav-info-label {
      font-size: .8em;
    }

    &:not(.js-is-top) {

      .l-site-nav-info {
          opacity: 0;
      }

    }

    &.js-is-top {

      .l-site-nav-info {
          opacity: .3;
          transition: opacity .2s 1s ease-in;
      }

    }

  }

  /* ************************************************************************************** */
  /* ************************************************************************************** */
  /* NO JS  */
  /* ************************************************************************************** */
  /* ************************************************************************************** */

  body.no-js-onload {

    .l-site-nav-menu {
      padding-left: 0;
      text-align: center;
    }

    .l-site-nav-item {
      display: inline-block;
      @media (max-width: $bp-mb) {
        padding: .2rem 1rem;
      }
      @media (min-width: $bp-tb) {
        padding: 1rem;
      }
    }

    .l-site-nav-link {

      &::before {
        display: none;
      }

      &::after {
        display: block;
        content: " ";
        height: .3rem;
        border-bottom: .2rem solid rgba(255,255,255,.1);
      }

      &:focus,
      &:hover {
        color: $global-shade-color-3;

        &::after {
          border-bottom-color: $global-shade-color-3;
        }

      }

    }

    .l-site-nav-info {
      display: none;
    }

  }

}

@media print {

  .l-site-nav {
    display: none;
  }

  .l-site-nav-info {
    display: none;
  }

}
