@media screen {

  .l-tiles-grid {
    display: flex;
    flex-flow: row wrap;
    padding-left: 0;
  }

  .l-tile {
    display: inline-block;
    position: relative;
    margin: .2rem;
  }

  .l-tile-title {
    display: block;
    position: relative;
    overflow: hidden;
    margin: auto;
    border-radius: 2rem;
    color: $global-text-color;
    text-decoration: none;
    background-color: $global-overlay-color-3;

    &::before {
      z-index: 50;
    }

    &::after {
      display: block;
      content: " ";
      width: 100%;
      height: 100%;
      position: absolute; top: 0; left: 0;
      border-radius: 2rem;
      background-color: $global-shade-color-3;
      transform: translateX(-100%);
      transition: transform .3s ease-in;
    }

    &:not(.ico-lbl) {
      padding: 1rem 1rem 1rem 3rem;

      &::before {
        $ico-size: 2rem;
        width: $ico-size;
        height: $ico-size;
        top: 0; bottom: 0; left: .7rem;
        font-size: $ico-size*.8;
        line-height: $ico-size;
      }

    }

    &.ico-lbl {
      padding: 1rem;

      &::before {
        position: relative;
        margin-right: .5rem;
      }

    }

    &:visited {
      color: rgba(255,255,255,.5);
    }

    &:focus {
      background-color: $global-shade-color-3;
      box-shadow: $box-shadow-5;
    }

    &:hover {
      box-shadow: $box-shadow-5;

      &::after {
        transform: translateX(0);
        transition: transform .3s ease-in;
      }

    }

    &:active {

      &::after {
        transform: scale(2);
        z-index: 10000;
        opacity: .3;
        transition: transform .005s ease-in;
      }

      .l-tile-title-label {

        &::before {
          opacity: 1;
          transform: scale(10);
          transition: transform .3s ease-in;
        }

      }

    }

  }

  .l-tile-title-label {
    position: relative;
    z-index: 1;

    &::before {
      display: block;
      content: " ";
      width: 2rem;
      height: 2rem;
      position: absolute; top: 0; bottom: 0; left: -2.3rem;
      z-index: -1;
      opacity: 0;
      border-radius: 100%;
      margin: auto;
      background-color: rgba(255,255,255,.3);
    }

  }

  /* ************************************************************************************** */
  /* ************************************************************************************** */
  /* JS  */
  /* ************************************************************************************** */
  /* ************************************************************************************** */

  body:not(.no-js-onload) {

    .l-tile-title {

      &:focus,
      &:hover {

        &::before {
          color: $global-shade-color-2;
        }

        .l-tile-title-label {
          color: $global-shade-color-2;
        }

      }

      &:focus {
        @include outlineMasker;
      }

    }

  }

  /* ************************************************************************************** */
  /* ************************************************************************************** */
  /* NO JS  */
  /* ************************************************************************************** */
  /* ************************************************************************************** */

  body.no-js-onload {

    .l-tile-title {

      &:focus,
      &:hover {

        &::before {
          color: $global-shade-color-1;
        }

        .l-tile-title-label {
          color: $global-shade-color-1;
        }

      }

    }

  }

}
