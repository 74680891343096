@media screen {

  .l-site-main,
  .l-site-aside {
    max-width: $max-layout-width;
    margin-right: auto;
    margin-left: auto;
    @media (max-width: $bp-mb) {
      padding-right: 1rem;
      padding-left: 1rem;
    }
    @media (min-width: $bp-tb) {
      padding-right: 2rem;
      padding-left: 2rem;
    }
  }

}
