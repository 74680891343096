@media screen {

    body {
        --scrollBarWidth: 0;
        min-width: $min-layout-width;
        height: 100%;
        overflow-x: hidden;
        font-family: $font-1;
        color: $global-text-color;
        background-color: $global-main-color;
        background-attachment: fixed;
        background-image: $gradient-1;
        -webkit-overflow-scrolling: touch;
    }

    abbr[title] {
        text-decoration: none;
    }

    .sr-only {
        @include masker;
    }

    @media speech {
        ::before,
        ::after {
            display: none;
            visibility: hidden;
        }
    }

    /* ************************************************************************************** */
    /* ************************************************************************************** */
    /* JS  */
    /* ************************************************************************************** */
    /* ************************************************************************************** */

    body:not(.no-js-onload) {
      background-color: $global-shade-color-2;
      background-repeat: no-repeat, no-repeat;
      background-attachment: fixed;
      background-size: cover, cover;
      background-image: $gradient-5, $bg-girl;
      transition: opacity .7s ease-out;
      @media (max-width: $bp-tv - 1) {
          background-position: center center;
      }

        &::before {
            display: block;
            content: " ";
            width: 100%;
            height: 100%;
            position: fixed; top: 0; left: 0;
            background-image: $gradient-3;
            transition: opacity .7s ease-out;
        }

        &.js-no-scroll {

          &::before {
            width: calc(100% + var(--scrollBarWidth));
          }

        }

    }

}

@media print {

    body {
        width: auto !important;
        margin: auto !important;
        font-family: serif;
        font-size: 12pt;
        color: $global-main-color!important;
        background-color: $global-text-color!important;
    }

    h1, h2, h3, h4, h5, h6, caption {
        page-break-after: avoid;
    }

    h1, h2, h3, h4, h5, h6 {
        display: block !important;
        font-family: $font-1;

        &[tabindex] {

            &::before {
                display: none;
            }

        }

    }

    p, blockquote {
        orphans: 3;
        widows: 3;
    }

    blockquote, ul, ol {
        page-break-inside: avoid;
    }

    h1 {
        page-break-before: always;
    }

    a {
        color: $global-main-color !important;
        text-decoration: none !important;

        &[target] {
            text-decoration: underline !important;

            &::after {
                content: " (" attr(href) ")";
                font-size: 10pt;
                font-style: italic;
                text-decoration: none;
            }
        }

    }

    p {
        line-height: 1.5;
        text-align: justify;
    }

    li  {
        list-style-type: square;
    }

    abbr {
        text-decoration: none !important;
    }

}
