@media screen {

  .l-site-accessibility {
    width: 100%;
    position: fixed; top: 0; left: 0;
    z-index: 7000;
    margin: auto;
  }

  .l-site-accessibility-item {
    display: block;
  }

  .l-site-accessibility-link {
    display: block;
    width: 100%;
    position: absolute; top: -10rem; left: 0;
    z-index: 10000;
    opacity: 0;
    border: 0;
    padding-left: 2.5rem;
    font-size: .8em;
    color: $global-text-color;
    text-align: left;
    text-decoration: none;
    background-color: rgba(0,0,0,.8);

    &::before {
      $ico-size: 2rem;
      width: $ico-size;
      height: $ico-size;
      top: 0; bottom: 0; left: 1rem;
      font-size: $ico-size;
      line-height: $ico-size;
    }

    &:focus {
      top: 0;
      opacity: 1;
      transition: opacity .3s ease-out;
    }

  }

  .l-site-accessibility-label {
    display: inline-block;
    padding: 1rem;

    &::after {
      display: block;
      content: " ";
      height: .2rem;
      border-bottom: .05rem solid $global-text-color;
    }

  }

}

@media print {

  .l-site-accessibility {
    display: none;
  }

}
