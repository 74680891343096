@media screen {

  .l-site {
    position: relative;
    z-index: 10;
    overflow-x: hidden;
    margin: auto;
  }

  .l-site-header {
    max-width: $max-layout-width;
    margin-right: auto;
    margin-left: auto;
  }

  .l-site-identity {
    padding: 0 1rem;
    text-align: center;
    @media (min-width: $bp-dt) {
      padding-right: 2rem;
      padding-left: 2rem;
    }
    @media (min-width: $bp-tv) {
      display: grid;
      grid-template-columns: 10rem 1fr;
      grid-template-areas:
            "a b"
            "c c";
      grid-gap: 2rem;
      align-items: center;
      padding-right: 0;
      padding-left: 0;
      text-align: left;
    }

  }

  .l-site-logo {
  	display: block;
  	width: 7rem;
  	height: auto;
  	position: relative;
  	margin-right: auto;
  	margin-left: auto;
    @media (min-width: $bp-dt) {
      width: 10rem;
    }
    @media (min-width: $bp-tv) {
      grid-area: a;
    }
  }

  .l-site-title {
    margin-top: .5rem;
    margin-bottom: 2rem;
    font-size: 2.5em;
    line-height: 1;
    letter-spacing: -.05em;
    text-shadow: $box-shadow-5;
    @media (min-width: $bp-dt) {
      margin-top: 1rem;
      font-size: 4.5em;
    }
    @media (min-width: $bp-tv) {
      grid-area: b;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .l-site-title-label {
    display: block;
  }

  .l-site-title-desc {
    display: block;
    font-size: .85em;
    color: $global-shade-color-3;
  }

  .l-site-slogan {
    display: inline-grid;
    grid-template-columns: 3.1rem 1fr;
    align-items: center;
  	position: relative;
  	padding: .5rem;
  	border-radius: 2rem;
    text-align: left;
  	background-color: $global-text-color;
  	box-shadow: $box-shadow-5;
    @media (min-width: $bp-dt) {

    }
    @media (min-width: $bp-tv) {
      grid-area: c;
      justify-self: center;
    }

    &::before {
      display: block;
    	content: " ";
    	width: 2rem;
    	height: 2rem;
    	position: absolute;
    	top: -.5rem;
    	right: 0;
    	left: 0;
    	z-index: -1;
    	margin: auto;
    	background-color: $global-text-color;
    	transform: rotate(45deg);
      @media (min-width: $bp-dt) {

      }
      @media (min-width: $bp-tv) {
        left: 2.4rem; right: auto;
      }
    }

  }

  .l-site-slogan-avatar {
    border-radius: 100%;
    @media (min-width: $bp-dt) {
      margin-top: 0;
    }
  }

  .l-site-slogan-punchline {
    margin: 0;
    padding: .5rem;
    @media (min-width: $bp-dt) {
      padding: 0 1rem;
    }
    @media (min-width: $bp-tv) {
      font-size: 1.3em;
    }
  }

  .l-site-slogan-quote {

      &::before {
        display: none;
      }

  }

  .l-site-header-cta {
    margin-top: 3rem;
    text-align: center;

    .btn-cta {
      display: inline-block;
    }

  }

  .l-site-header-min {
    display: grid;
    grid-template-columns: 3rem 1fr;
    align-items: center;
    width: 100%;
    font-size: .9em;
  }

  .l-site-nav-logo {
    width: 3rem;
    height: 3rem;
    overflow: hidden;
    margin: 0;
    color: rgba(255,255,255,0);
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-image: $bg-logo-min;
  }

  .l-site-nav-breadcrumbs {
    margin: 0;
    padding: .5rem 0;
    line-height: 1.5;
    font-weight: 700;
    color: $global-text-color;
  }

  .l-site-nav-breadcrumbs-link {
    color: $global-shade-color-3;
  }

  /* ************************************************************************************** */
  /* ************************************************************************************** */
  /* JS  */
  /* ************************************************************************************** */
  /* ************************************************************************************** */

  body:not(.no-js-onload) {

    .l-site-header {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      min-height: 100vh;
      overflow: hidden;
    }

    .l-site-slogan {
      font-weight: 300;
      color: $global-shade-color-2;
    }

    .l-site-slogan-punchline {
      box-sizing: border-box;
    }

    .l-site-slogan-quote {
      position: relative;
      line-height: 1.3rem;

      &::after {
        content: " ";
        margin-left: .2rem;
        border-right: .5rem solid $global-shade-color-2;
      }

      &:not(.js-init) {

        &::after {
          opacity: 1;
          transition: opacity .3s;
        }

      }

      &.js-init {

        &::after {
          opacity: 0;
          transition: opacity .3s;
        }

      }

      &.js-complete {

        &::after {
          animation: blink linear 1s infinite;
        }

      }

    }

    .l-site-header-min {
      background-color: $global-overlay-color-3;
      @media (min-width: $bp-dt) {
        position: fixed; top: 0;
        z-index: 4000;
      }
    }

    &:not(.js-is-loading) {

      .l-site-logo {
        z-index: 150;
        opacity: 1;
        transform: translateY(0);
        transition: transform .3s ease-in, opacity .1s ease-in;
        @media (min-width: $bp-tv) {
          transform: translateX(0);
          transition-delay: .5s;
        }
      }

      .l-site-title-label {
        opacity: 1;
        transition: opacity .3s .1s ease-in;
        @media (min-width: $bp-tv) {
          transition-delay: .6s;
        }
      }

      .l-site-title-desc {
        opacity: 1;
        transition: opacity .3s .1s ease-in;
        @media (min-width: $bp-tv) {
          transition-delay: .6s;
        }
      }

      .l-site-slogan {
        opacity: 1;
        z-index: 150;
        transform: translateY(0);
        transition: transform .1s .5s ease-in, opacity .3s .9s ease-in;
        @media (min-width: $bp-tv) {
          transition-delay: 1s;
        }

        > p {
          opacity: 1;
          transition: opacity .3s .5s ease-in;
          @media (min-width: $bp-tv) {
            transition-delay: 1s;
          }
        }

      }

    }

    &.js-is-loading {

      .l-site-logo {
        opacity: 0;
        transform: translateY(-200%);
        @media (min-width: $bp-tv) {
          transform: translateX(200%);
        }
      }

      .l-site-title-label,
      .l-site-title-desc {
        opacity: 0;
      }

      .l-site-slogan {
        opacity: 0;
        transform: translateY(10rem);

        > p {
          opacity: 0;
        }

      }

    }

    &:not(.js-intro-complete) {

      .l-site-header-cta {
        opacity: 0;
      }

    }

    &.js-intro-complete {

      .l-site-header-cta {
        opacity: 1;
        transition: opacity .3s ease-in;
      }

    }

  }

  /* ************************************************************************************** */
  /* ************************************************************************************** */
  /* NO JS  */
  /* ************************************************************************************** */
  /* ************************************************************************************** */

  body.no-js-onload {

    .l-site-header {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      padding: 2rem 1rem;
    }

    .l-site-slogan {
      color: $global-shade-color-1;
    }

    .l-site-slogan-quote {

        &::after {
          display: none;
        }

    }

    &.l-site-splash {

      .l-site-header {
        min-height: 100vh;
      }

    }

  }

}

@media print {

  .l-site-header {
    margin-top: 5mm;
    border-bottom: 5mm solid $global-main-color;
  }

  .l-site-header-min {
    display: none;
  }

  .l-site-title {
    margin-top: 2mm;
    line-height: 1;

    &::before {
      display: block;
      content: $bg-logo-print;
      float: left;
      margin-top: -2mm;
      margin-right: 5mm;
    }

    &::after {
      @include clear-both;
    }

  }

  .l-site-title-label {
    display: block;
    font-size: 40pt;
    letter-spacing: -1mm;
  }

  .l-site-title-desc {
    display: block;
    font-size: 36pt;
    letter-spacing: -.9mm;

    &::after {
      display: block;
      content: "www.thomas-marget.fr";
      font-size: 24pt;
      letter-spacing: -.7mm;
      color: rgba(0,0,0,.5);
    }

  }

}
