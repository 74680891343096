@media screen {

  .btn-cta {
    display: block;
    box-sizing: content-box;
    position: relative;
    padding: 0;
    border: 0;
    border-radius: 2rem;
    text-align: center;
    text-decoration: none;
    background-color: rgba(0,0,0,0);

    &::before {
      display: block;
      content: " ";
      width: 1rem;
      height: 1rem;
      position: absolute; top: 0; right: 0; bottom: 0; left: 0;
      z-index: -1;
      opacity: 0;
      margin: auto;
      border-radius: 100%;
      background-image: radial-gradient(rgba(255,255,255,.3),rgba(255,255,255,0));
      transition: transform .2s ease-in, opacity .1s ease-in;
    }

    &:focus,
    &:hover {

      &:not([disabled]) {

        .btn-cta-label {
          border-color: $global-shade-color-2;
          background-image: radial-gradient(rgba(0,0,0,.05),rgba(0,0,0,.2));
          box-shadow: 2px 2px 2px rgba(0,0,0,.5);
        }

      }

    }

    &:active {

      &:not([disabled]) {

        &::before {
          opacity: .5;
          transform: scale(10);
          transition: transform .2s ease-in, opacity .1s ease-in;
        }

      }

    }

    &[disabled] {

    }

  }

  .btn-cta-label {
    display: block;
    position: relative;
    z-index: 5;
    padding: .7rem 1rem;
    border: .3rem solid $global-shade-color-3;
    border-radius: 2rem;
    font-weight: 700;
    background-color: $global-shade-color-3;
  }

  /* ************************************************************************************** */
  /* ************************************************************************************** */
  /* JS  */
  /* ************************************************************************************** */
  /* ************************************************************************************** */

  body:not(.no-js-onload) {

    .btn-cta {
      cursor: pointer;

      &:focus {
        @include outlineMasker;
      }

    }

    .btn-cta-label {
      color: $global-shade-color-2;
    }

  }

  /* ************************************************************************************** */
  /* ************************************************************************************** */
  /* NO JS  */
  /* ************************************************************************************** */
  /* ************************************************************************************** */

  body.no-js-onload {

    .btn-cta-label {
      color: $global-shade-color-1;
    }

  }

}
