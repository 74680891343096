@media screen {

  /* ************************************************************************************** */
  /* ************************************************************************************** */
  /* JS  */
  /* ************************************************************************************** */
  /* ************************************************************************************** */

  body:not(.no-js-onload) {

    .js-panel-btn-label {
      @include btn-label;
    }

    .js-panel-btn {
      @include btn;
      position: absolute; top: .5rem;

      &:focus,
      &:hover {

        .js-panel-btn-label {
          @include btn-label-focus;
        }

      }

      &:focus {
        @include outlineMasker;
      }

      &:active {

        .js-panel-btn-label {
          @include btn-label-click;
        }

      }

      &.js-panel-btn-active {

        &::before {
          font-size: 1.2rem;
        }

        &:focus,
        &:hover {
          background-color: $global-shade-color-3;
          box-shadow: $box-shadow-3;

          &::before {
            font-size: 1rem;
          }

        }

      }

    }

    .js-panel {
      min-width: $min-layout-width;
    }

    .js-panel-scroll {
      overflow-y: auto;
    }

    .js-panel-overlay {
      width: 100%;
      height: 100vh;
      position: fixed; top: 0;
      z-index: 5000;
      background-color: $global-shade-color-2;
      background-image: $gradient-5;
      transition: all .3s ease-out;

      &:not(.js-offset) {
        transition: all .3s .3s;
      }

      &.js-offset {
        transition: all .3s ease-in .3s;
      }

      &.js-panel-dir-left {
        left: 0;

        .js-panel-btn {
          right: -3.5rem;
          transition: right .3s ease-out .3s;

          &.js-panel-btn-active {
            right: .5rem;
            transition: right .3s ease-out .3s;

            &::before {
              content: "\e902";
            }

            .js-panel-btn-label {
              @include btn-label-dir(left);
            }

          }

        }

        .js-panel-btn-label {
          @include btn-label-dir(right);
        }

        &.js-offset {
          left: -100%;
        }

      }

      &.js-panel-dir-right {
        right: 0;

        .js-panel-btn {
          left: -3.5rem;
          transition: left .3s ease-out .3s;

          &.js-panel-btn-active {
            left: .5rem;
            transition: left .3s ease-out .3s;

            &::before {
              content: "\e903";
            }

            .js-panel-btn-label {
              @include btn-label-dir(right);
            }

          }

        }

        .js-panel-btn-label {
          @include btn-label_dir(left);
        }

        &.js-offset {
          right: -100%;
        }

      }

    }

    &.js-no-scroll {

      .js-panel-dir-left {

          .js-panel-btn {
            //right: calc(-3.5rem - var(--scrollBarWidth));
          }

      }

      .js-panel-dir-right {

          .js-panel-btn {
            //left: calc(-3.5rem - var(--scrollBarWidth));
          }

      }

    }

    &.js-modal-active {

      .js-panel-overlay {

        .js-panel-btn {
          transition: none;
        }

      }

    }

  }

  /* ************************************************************************************** */
  /* ************************************************************************************** */
  /* NO JS  */
  /* ************************************************************************************** */
  /* ************************************************************************************** */

  body.no-js-onload {

    .js-panel {
      padding: 1rem;
    }

    .js-panel-btn {
      display: none;
    }

  }

}

@media print {

  .l-panel {
    display: none;
  }

}
