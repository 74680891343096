/* ----------------------------------------------------------------------------------------------------------- */
/* --- LAYOUT --- */
/* ----------------------------------------------------------------------------------------------------------- */

@mixin masker {
  width: 1px;
  height: 1px;
  position: absolute;
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  padding: 0;
  margin: -1px;
  border: 0;
}

@mixin undo-masker {
  width: inherit;
  height: inherit;
  position: inherit;
  clip: inherit;
  overflow: inherit;
  padding: inherit;
  margin: inherit;
  border: inherit;
}

@mixin hyphen-auto {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

@mixin clear-both {
  display: block;
  content: "";
  height: 0;
  clear: both;
  overflow: hidden;
}

@mixin no-gutter {
  margin: 0;
  padding: 0;
}

@mixin outlineMasker {
  outline-width: 0;
  outline-color: rgba(255,255,255,0);
}

/* ----------------------------------------------------------------------------------------------------------- */
/* --- TEXT --- */
/* ----------------------------------------------------------------------------------------------------------- */

@mixin link-classic {
  display: inline-block;
  position: relative;
  line-height: 1;
  color: $global-shade-color-2;
  text-decoration: none;

  &::after {
    display: block;
    content: " ";
    width: 100%;
    height: .1rem;
    border-bottom: .05rem solid $global-shade-color-2;
  }

  &:focus,
  &:hover {

    &::after {
      position: absolute; top: calc(100% - .1rem);
      border-bottom-width: .1rem;
    }

  }

  &:focus {
    background-color: rgba(0,0,0,.5);
  }

  &:active {
    color: $global-shade-color-2f;

    &::after {
      border-bottom-width: .1rem;
      border-bottom-color: $global-shade-color-2f;
    }

  }

  &:visited {
    color: $global-shade-color-3;

    &::after {
      border-bottom-color: $global-shade-color-3;
    }

  }

  &[target="_blank"]:focus,
  &[target="_blank"]:hover {

    &::before {
      display: inline-block;
      content: attr(data-label);
      position: absolute; left: 100%; bottom: calc(100% + .1rem);
      margin-right: .3rem;
      padding: .2rem;
      font-size: .7em;
      color: $global-text-color;
      background-color: rgba(0,0,0,1);
    }

  }
}

/* ----------------------------------------------------------------------------------------------------------- */
/* --- BUTTONS --- */
/* ----------------------------------------------------------------------------------------------------------- */

@mixin btn {
  $btn-size: 3rem;
  display: block;
  box-sizing: border-box;
  width: $btn-size;
  height: $btn-size;
  z-index: 150;
  overflow: hidden;
  opacity: 1;
  border: .3rem solid $global-text-color;
  border-radius: 100%;
  line-height: $btn-size;
  background-color: $global-shade-color-1;
  cursor: pointer;

  &::before {
    $ico-size: 2.4rem;
    width: $ico-size;
    height: $ico-size;
    font-size: 1.5rem;
    line-height: $ico-size;
    color: $global-text-color;
    top: 0; right: 0; bottom: 0; left: 0;
    transition: transform .1s ease-in;
  }

  &::after {
    display: block;
    content: " ";
    width: 100%;
    height: 100%;
    position: absolute; top: 0; left: 0;
    z-index: -1;
    opacity: 0;
    border-radius: 100%;
    background-color: $global-text-color;
    transition: all .3s ease-in;
  }

  &:focus,
  &:hover {
    overflow: visible;
    border-color: $global-shade-color-2;
    background-color: $global-shade-color-3;

    &::before {
      color: $global-shade-color-2;
      transform-origin: center center;
      transform: scale(.8);
      transition: transform .1s ease-out;
    }

  }

  &:active {

    &::after {
      z-index: 10000;
      opacity: .2;
      transform: scale(3);
      transition: transform .005s ease-in;
    }

  }

}

@mixin btn-label {
  display: inline-block;
  width: 8rem;
  height: 2rem;
  position: absolute; top: 0; bottom: 0;
  opacity: 0;
  margin: auto;
  padding: 0 .7rem;
  border-radius: 2rem;
  font-size: .7em;
  line-height: 2rem;
  color: $global-shade-color-2;
  background-color: $global-text-color;
  box-shadow: $box-shadow-3;
  transition: opacity .3s ease-in;
}

@mixin btn-label-dir($dir) {
  @if $dir == right {
    right: auto;
    left: calc(100% + .5rem);
  }@else if $dir == left {
    right: calc(100% + .5rem);
    left: auto;
  }
}

@mixin btn-label-focus {
  opacity: 1;
  transition: opacity .3s ease-out;
}

@mixin btn-label-click {
  opacity: 0;
}

/* ----------------------------------------------------------------------------------------------------------- */
/* --- IMAGES --- */
/* ----------------------------------------------------------------------------------------------------------- */

@mixin img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

@mixin img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

/* ----------------------------------------------------------------------------------------------------------- */
/* --- ICONS --- */
/* ----------------------------------------------------------------------------------------------------------- */

@mixin font-icons {
  @include extra-icon;
  font-family: $font-icons;
}

@mixin extra-icon {
  display: inline-block;
  position: absolute;
  margin: auto;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  color: $global-text-color;
  text-align: center;
  text-transform: none;
  white-space: pre-wrap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
}

@mixin anchor-label($ico-size, $bg-color) {
  display: inline-block;
  position: relative;
  &::after {
    @include font-icons;
    content: "\e920";
    width: $ico-size;
    height: $ico-size;
    top: 0; right: $ico-size*.5; bottom: 0;
    opacity: 0;
    border-radius: 100%;
    font-size: $ico-size / 2;
    line-height: $ico-size;
    background-color: $bg-color;
  }
  &:focus {
    padding-right: $ico-size + 1.5;
    &::after {
      opacity: 1;
      transition: opacity .3s ease-out;
    }
  }
}

/* ----------------------------------------------------------------------------------------------------------- */
/* --- IFRAMES --- */
/* ----------------------------------------------------------------------------------------------------------- */

@mixin iframe-responsive {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;

  > iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    @include pos-coord(0, auto, auto, 0);
    border: 0;
  }

}

/* ----------------------------------------------------------------------------------------------------------- */
/* --- FORM --- */
/* ----------------------------------------------------------------------------------------------------------- */

@mixin form-switch-ipt {
  position: absolute;
  opacity: 0;

  + span {
    @include form-switch-btn;
  }

  &:checked {

    + span {

      &::before {
        margin: 0;
      }

      &::after {
        left: 1.7rem;
        color: transparent;
        box-shadow: $box-shadow-4;
        transition: all .3s;
      }

    }

  }

  &:focus,
  &:hover {

    + span {

      &::after {
        background-color: $global-shade-color-3;
        transition: border-color .3s ease-in;
      }

    }

  }

}

@mixin form-switch-btn {
  display: block;
  position: relative;
  padding-left: 4.5rem;
  cursor: pointer;

  &::before {
    $ico-size: 2rem;
    display: block;
    content: "\002714";
    width: $ico-size + 1;
    height: $ico-size;
    position: absolute; top: 0; left: 0;
    padding-left: .5rem;
    border-radius: 2rem;
    line-height: $ico-size;
    color: $global-text-color;
    background-color: rgba(0,0,0,.8);
    transition: border-color .3s ease-in;
  }

  &::after {
    $ico-size: 1.6rem;
    display: block;
    content: " ";
    width: $ico-size;
    height: $ico-size;
    position: absolute; top: .2rem; left: .2rem;
    border-radius: 100%;
    font-family: sans-serif;
    font-size: 1.4rem;
    line-height: $ico-size;
    color: $global-shade-color-2;
    text-align: center;
    background-color: $global-text-color;
    transition: left .3s;
  }

}
