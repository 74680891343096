@media screen {

  .l-page-section {

    &:nth-child(1) {

      .l-page-section-title {
        margin-top: 0;
      }

    }

    .l-page-section-cta {
      text-align: center;

      > .btn-cta {
        display: inline-block;
        margin: 1rem auto;
      }

    }

  }

  .l-page-section-title {
    margin-top: 3rem;
    font-size: 2.2em;
    line-height: 1;
    letter-spacing: -.05em;
    text-shadow: $box-shadow-5;
    @media (min-width: $bp-dt) {
      margin-top: 5rem;
      font-size: 4em;
    }

    &[tabindex] {
      @include anchor-label(2rem, rgba(0,0,0,.9));
    }

  }

  .l-page-section-intro {

    @media (min-width: $bp-dt) {
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-template-areas: "a b";
      grid-gap: 2rem;
      align-items: center;
    }

    .l-page-section-story {
      @media (min-width: $bp-dt) {
        grid-area: b;
      }
    }

    .l-page-section-figure {
      @media (min-width: $bp-dt) {
        grid-area: a;
      }
    }

    + * {
      @media (min-width: $bp-dt) {
        margin-top: 3rem;
      }
    }

  }

  .l-page-section-intro-hero {
    display: grid;
    justify-items: center;

    .l-page-section-title {
      margin-bottom: 2rem;
    }

    .l-page-section-story {
      @media (min-width: $bp-dt) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 2rem;
        align-items: center;
      }
    }

  }

  .l-page-section-story {

    > p {
      padding: .5rem 0 .5rem 3.7rem;
      line-height: 1.5;
      @media (min-width: $bp-dt) {
        padding-left: 4rem;
      }

      &::before {
        $ico-size: 3rem;
        width: $ico-size;
        height: $ico-size;
        top: .7rem; left: 0;
        font-size: $ico-size;
        line-height: $ico-size;
        @media (min-width: $bp-dt) {
          top: 0; bottom: 0;
        }
      }

      a:not(.btn-cta) {
        @include link-classic;
      }

    }

  }

  .l-page-section-figure {
    margin: 0;
    @media (max-width: $bp-dt - 1) {
      @include masker;
    }
  }

  .l-page-section-figure-media {
    fill: rgba(255,255,255,.1);
  }

  .l-page-sub-section {

    &[class*="ico-"] {

      &::before {
        $ico-size: 10rem;
        width: $ico-size;
        height: $ico-size;
        border-radius: 100%;
        font-size: 7rem;
        line-height: $ico-size;
        color: rgba(255, 255, 255, .5);
        @media (max-width: $bp-mb) {
          top: 6rem; right: 0; left: 0;
        }
        @media (min-width: $bp-tb) {
          top: 5rem; left: 1rem;
        }
      }

      &::after {
        $ico-size: 10rem;
        display: block;
        content: " ";
        width: $ico-size;
        height: $ico-size;
        position: absolute; left: 0;
        z-index: -1;
        margin: auto;
        border: 1rem solid rgba(255,255,255,.2);
        border-radius: 100%;
        @media (max-width: $bp-mb) {
          top: 5rem; right: 0;
        }
        @media (min-width: $bp-tb) {
          top: 4.5rem;
        }
      }

    }

  }

  .l-page-sub-section-title {
    display: inline-block;
    padding: .5rem 1rem;
    border-radius: 2rem;
    font-size: 1.1em;
    background-color: $global-text-color;
    box-shadow: $box-shadow-5;
    @media (max-width: $bp-mb) {
      margin-bottom: 0;
    }

    &[tabindex] {
      @include anchor-label(1.6rem, $global-shade-color-1);
    }

  }

  /* ************************************************************************************** */
  /* ************************************************************************************** */
  /* JS  */
  /* ************************************************************************************** */
  /* ************************************************************************************** */

  body:not(.no-js-onload) {

    .l-page-section-title {

      &[tabindex] {

        &:focus {
          @include outlineMasker;
        }

      }

      > span {
        opacity: 0;
        color: $global-shade-color-3;
        transition: opacity .3s ease-in .2s;
      }

      &.js-animate--active > span {
        opacity: 1;
        transition: opacity .3s ease-in .2s;
      }

    }

    .l-page-section-story {

      > p {

        > a:not(.btn-cta) {

          &:focus {
            @include outlineMasker;
          }

        }

        > strong {
          letter-spacing: -.02em;
        }

        > em {
          font-style: normal;

          &::before {
            content: "#";
            font-weight: 700;
            color: $global-shade-color-2;
          }

        }

      }

    }

    .l-page-sub-section-title {
      font-weight: 300;
      color: $global-shade-color-2;

      &[tabindex] {

        &:focus {
          @include outlineMasker;
        }

      }

    }

    .l-page-section-story {

      > p {

        &::before {
          color: $global-shade-color-2;
        }

      }

    }

  }

  /* ************************************************************************************** */
  /* ************************************************************************************** */
  /* NO JS  */
  /* ************************************************************************************** */
  /* ************************************************************************************** */

  body.no-js-onload {


    .l-page-section-title {
      color: $global-shade-color-3;
    }

    .l-page-sub-section-title {
      color: $global-shade-color-1
    }

    .l-page-section-story {

      > p {

        &::before {
          color: $global-text-color;
        }

      }

    }

  }

}

@media print {

  .l-page-section-nav,
  .l-page-sub-section-nav,
  .l-page-section-cta {
    display: none;
  }

  .l-page-section-title {
    margin-top: 1cm;
    font-size: 30pt;
  }

  .l-page-sub-section {
    margin-left: 2%;

    &::before {
      display: none;
    }

  }

  .l-page-sub-section-title {
    margin-top: 1cm;
    font-size: 22pt;
  }

}
