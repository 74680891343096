/***************************************************************************************************************/
/***************************************************************************************************************/
/* --- SASS VARS --- */
/***************************************************************************************************************/
/***************************************************************************************************************/

/* ----------------------------------------------------------------------------------------------------------- */
/* --- FONTS --- */
/* ----------------------------------------------------------------------------------------------------------- */

$font-icons: "tmicons";
$font-1: "Poppins", Helvetica, Arial, Garuda, sans-serif;

/* ----------------------------------------------------------------------------------------------------------- */
/* --- COLORS --- */
/* ----------------------------------------------------------------------------------------------------------- */

$global-main-color:       #000;
$global-text-color:       #fff;

$global-shade-color-1:    #1c003d;
$global-shade-color-2:    #ff0066;
$global-shade-color-2f:   #cc0000;
$global-shade-color-3:    #bbe004;

$global-overlay-color:    rgba(0,0,0,.95);
$global-overlay-color-2:  rgba(0,0,0,.4);
$global-overlay-color-3:  rgba(32,23,43,.8);

$global-error-color:      #ed3605;
$global-valid-color:      #89bf55;

/* ----------------------------------------------------------------------------------------------------------- */
/* --- GRADIENTS --- */
/* ----------------------------------------------------------------------------------------------------------- */

$gradient-1: linear-gradient(20deg,$global-main-color 0,$global-shade-color-1 50%);
$gradient-2: linear-gradient(5deg,$global-shade-color-2 0,$global-shade-color-2f 100%);
$gradient-3: linear-gradient(-20deg,rgba(0,0,0,.9) 0,rgba(0,0,0,0) 50%);
$gradient-4: linear-gradient(-45deg,rgba(0,0,0,0) 0,rgba(0,0,0,.7) 100%);
$gradient-5: linear-gradient(20deg,rgba(255,0,102,.6) 0,$global-shade-color-1 50%);
$gradient-6: linear-gradient(to top,rgba(0,0,0,1) 0,rgba(0,0,0,0) 100%);
$gradient-7: linear-gradient(25deg,$global-shade-color-2 0,$global-shade-color-2f 100%);

/* ----------------------------------------------------------------------------------------------------------- */
/* --- LAYOUT --- */
/* ----------------------------------------------------------------------------------------------------------- */

$bp-mb: 500px;
$bp-tb: 501px;
$bp-dt: 701px;
$bp-tv: 1001px;
$bp-earth: 1001px;

$min-layout-width: 20rem;
$max-layout-width: 50rem;

/* ----------------------------------------------------------------------------------------------------------- */
/* --- SHADOWS --- */
/* ----------------------------------------------------------------------------------------------------------- */

$box-shadow-1: .1rem .1rem .1rem rgba(0,0,0,.5);
$box-shadow-2: .1rem .1rem .1rem rgba(0,0,0,.2);
$box-shadow-3: .1rem .1rem .1rem rgba(0,0,0,.3);
$box-shadow-4: .1rem .1rem 0 rgba(0,0,0,.2);
$box-shadow-5: .125rem .125rem .125rem rgba(0,0,0,.5);

/* ----------------------------------------------------------------------------------------------------------- */
/* --- BACKGROUND-IMAGES --- */
/* ----------------------------------------------------------------------------------------------------------- */

$bg-logo: url(../images/logo.svg);
$bg-logo-min: url(../images/logo-min.svg);
$bg-logo-mono: url(../images/logo-mono.svg);
$bg-logo-print: url(../images/logo-print.png);
$bg-girl: url(../images/splash.jpg);
$bg-sea: url(../images/splash-menu.jpg);
$bg-space: url(../images/splash-404.jpg);
