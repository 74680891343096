@media screen {

  .form {
    position: relative;
    text-align: left;
    background-color: transparent;
    @media (max-width: $bp-mb) {
      margin-top: 1.5rem;
    }
    @media (min-width: $bp-tb) {
      margin-top: 3rem;
    }

    textarea {
      height: 10rem;
    }

  }

  .form-field {
    margin: 0;
    padding-bottom: 1rem;
  }

  .form-input-area {
    display: block;
    position: relative;
    border-radius: .2rem;
    background-color: rgba(255,255,255,.1);
  }

  .form-label {
    display: block;
    width: calc(100% - 2rem);
    position: absolute; top: .8rem; left: 1rem;
    font-size: .7rem;
    font-weight: 700;
    color: $global-text-color;
    text-transform: uppercase;
  }

  .form-required {
    font-size: .7em;
  }

  .form-ipt-text {
    display: block;
    width: calc(100% - 1rem);
    padding: .5rem;
    font-weight: 700;
    box-shadow: none;
    transition: background-color .3s .3s ease-in;

    &[required] {
      width: calc(100% - 2.5rem);
      padding-right: 2rem;

      + .form-label {

        &::after {
          $ico-size: 2rem;
          display: block;
          width: $ico-size;
          height: $ico-size;
          position: absolute; top: -.7rem; right: .2rem;
          opacity: 0;
          font-size: 1.6rem;
          line-height: $ico-size;
          font-weight: 300;
          text-align: center;
          transition: opacity .1s ease-in;
        }

      }

    }

    &:valid {

      + .form-label {

        &::after {
          content: "\1F60A";
        }

      }

    }

    &:invalid {

      + .form-label {

        &::after {
          content: "\1F641";
        }

      }

    }

  }

  .form-ipt-label {
    font-size: .8em;
    color: $global-text-color;
  }

  .form-tip {
    display: block;
    padding-top: .5rem;
    font-size: .7em;
  }

  .form-optin {

    .form-input-area {
      position: relative;
      padding: 1rem;
    }

  }

  .form-submit {
    padding-top: 1rem;
    text-align: right;
  }

  .form-btn {
    @extend .btn-cta;
    display: inline-block;
  }

  .form-btn-label {
    @extend .btn-cta-label;
    padding-top: .7rem;
    padding-bottom: .7rem;
    font-size: 1.1em;
  }

  /* ************************************************************************************** */
  /* ************************************************************************************** */
  /* JS  */
  /* ************************************************************************************** */
  /* ************************************************************************************** */

  body:not(.no-js-onload) {

    .js-form-input {

      &:not(.js-focused) {
        padding: 0 1rem;
        transition: background-color .3s ease-in;

        &:hover {
          background-color: rgba(255,255,255,.15);
          transition: background-color .3s ease-out;
        }

        .form-ipt-text {
          color: rgba(255,255,255,0);
        }

        .form-tip {
          @include masker;
        }

      }

      &.js-focused {
        padding: 2rem 1rem 1rem 1rem;
        transition: padding .3s;

        .form-ipt-text {
          background-color: $global-overlay-color-3;
          transition: background-color .3s .3s ease-out;

          &[required] {
            width: calc(100% - 2.5rem);
            padding-right: 2rem;

            + .form-label {

              &::after {
                top: 1.5rem;
                opacity: 1;
                transition: opacity .2s .2s ease-out;
              }

            }

          }

          &:focus,
          &:hover {
            box-shadow: $box-shadow-4;
            transition: border-color .3s ease-in;
          }

          &:focus {

            &:valid {
              border-bottom-color: $global-valid-color;
            }

            &:invalid {
              border-bottom-color: $global-error-color;
            }

          }

          &:hover {
            border-bottom-color: $global-shade-color-3;
          }

        }

        .form-tip {
          color: rgba(255,255,255,.5);
        }

      }

    }

    .form-ipt-text {
      border: 0;
      border-bottom: .2rem solid transparent;
      border-radius: .5rem;
      color: $global-text-color;
      background-color: transparent;

      &:focus {
        @include outlineMasker;
      }

    }

    .form-optin {

      .form-ipt-cb {
        @include form-switch-ipt;
      }

    }

  }

  /* ************************************************************************************** */
  /* ************************************************************************************** */
  /* NO JS  */
  /* ************************************************************************************** */
  /* ************************************************************************************** */

  body.no-js-onload {

    .form-ipt-text {
      border: 0;
    }

    .js-form-input {
      padding: 2rem 1rem 1rem 1rem;
    }

  }

}
