@media screen {

  /* ************************************************************************************** */
  /* ************************************************************************************** */
  /* JS  */
  /* ************************************************************************************** */
  /* ************************************************************************************** */

  body:not(.no-js-onload) {

    @keyframes pacman1 {
      0% {
        transform: rotate(0)
      }
      50% {
        transform: rotate(45deg)
      }
      100% {
        transform: rotate(0)
      }
    }

    @keyframes pacman2 {
      0% {
        transform: rotate(-180deg)
      }
      50% {
        transform: rotate(-225deg)
      }
      100% {
        transform: rotate(-180deg)
      }
    }

    .progress-pacman {
      display: block;
      width: calc(100% - 2rem);
      max-width: 20rem;
      height: 2.5rem;
      position: fixed; right: 1rem; bottom: 1rem;
      z-index: 4000;
      overflow: hidden;
      border-radius: 2rem;
      background-color: $global-shade-color-1;
    }

    .pacman-animate {
      width: calc(100% - 1rem);
      height: 100%;
      position: relative;
      overflow: hidden;
      margin: 0 .5rem;
    }

    .pacman-food {
      display: grid;
      grid-template-columns: repeat(var(--foodIndex), 1fr);
      width: 100%;
      position: absolute; right: 0; bottom: 1.05rem;
      transform: translateX(var(--scrollIndex));
      transition: transform .1s linear;
    }

    .pacman-food span {
      display: block;
      width: .4rem;
      height: .4rem;
      border-radius: 100%;
      background-color: $global-shade-color-2;
    }

    .pacman-body {
      box-sizing: content-box;
      width: 2.5rem;
      height: 2.5rem;
      position: absolute; right: 0; bottom: .3rem;
      z-index: 150;
    }

    .pacman-body__part {
      width: 30px;
      height: .938rem;
      position: absolute; top: .625rem; left: .625rem;
      border-radius: 1.875rem 1.875rem 0 0;
      background-color: $global-shade-color-3;
      transform-origin: .938rem .938rem;
    }

    .pacman-body__part--top {
      animation: pacman1 .3s linear paused infinite;
    }

    .pacman-body__part--bot {
      animation: pacman2 .3s linear paused infinite;
    }

    .pacman-body__part--left {
      transform: rotate(90deg);
    }

    .pacman-alert {
      display: inline-block;
      width: 100%;
      height: 2rem;
      margin: 0;
      padding: 0 .5rem;
      border-radius: 2rem;
      font-size: .7em;
      line-height: 2.5rem;
    }

    .pacman-alert-btn {
      display: block;
      color: $global-text-color;
      text-decoration: none;
    }

    &:not(.js-is-scrolling) {

      .pacman-body__part {
          animation-play-state: paused;
      }

    }

    &.js-is-scrolling {

      .pacman-body__part {
          animation-play-state: running;
      }

    }

    &.js-is-top,
    &.js-no-scroll {

      .progress-pacman {
        opacity: 0;
        transition: opacity .3s ease-in;
      }

    }

    &:not(.js-is-end) {

      .pacman-alert {
        opacity: 0;
        transform: translateX(100%);
      }

    }

    &.js-is-end {

      .progress-pacman {
        background-color: $global-shade-color-2;
      }

      .pacman-alert {
        opacity: 1;
        transform: translateX(0);
        transition: transform .3s ease-in;
      }

    }

  }

  /* ************************************************************************************** */
  /* ************************************************************************************** */
  /* NO JS  */
  /* ************************************************************************************** */
  /* ************************************************************************************** */

  body.no-js-onload {

    .progress-pacman {
      display: none;
    }

  }

}

@media print {

  .progress-pacman {
    display: none;
  }

}
